<template>
  <div class="equipment-info-wrapper">
    <van-nav-bar
      title="设备基本信息"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    ></van-nav-bar>
    <div class="equipment-info">
      <van-image
        v-if="pictureUrl"
        width="100%"
        height="150"
        :src="pictureUrl"
        @click="ImagePreview([pictureUrl])"
      />
      <template v-for="item in infos">
        <van-field
          :key="item.label"
          :value="item.formatter ? item.formatter(info) : info[item.prop]"
          :label="item.label"
          :class="[
            item.prop === 'equipmentStatusName'
              ? `mark${info.equipmentStatus}`
              : ''
          ]"
          readonly
          placeholder=""
          input-align="right"
        />
        <div
          v-if="item.margin"
          :key="'margin-' + item.label"
          style="background-color: #ECEEF2;height: 12px"
        ></div>
      </template>
    </div>
  </div>
</template>

<script>
import { getEquipmentInfo } from "@/api/basics/equipmentRx";
import { getFileUrlForFileSystem } from "@/utils/file";
import { ImagePreview } from "vant";

export default {
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      info: {},
      infos: [
        {
          label: "设备名称",
          prop: "name"
        },
        {
          label: "所属部门",
          prop: "departmentName"
        },
        {
          label: "所属场所设施",
          prop: "poiName"
        },
        {
          label: "特种设备",
          prop: "specialCategoryName",
          formatter: item => {
            if (item.specialCategoryName) {
              return `是/${item.specialCategoryName}`;
            }
            return "否";
          }
        },
        {
          label: "安全附件",
          prop: "safetyCategoryName",
          formatter: item => {
            if (item.safetyCategoryName) {
              return `是/${item.safetyCategoryName}`;
            }
            return "否";
          }
        },
        {
          label: "设备类型",
          prop: "typeName"
        },
        {
          label: "设备分类",
          prop: "treeName"
        },
        {
          label: "设备等级",
          prop: "levelName"
        },
        {
          label: "设备状态",
          prop: "equipmentStatusName"
        },
        {
          label: "是否报废",
          formatter: item => {
            if (item.scrapVo && item.scrapVo.scrapDate) {
              return "是";
            }
            return "否";
          }
        },
        {
          label: "规格型号",
          prop: "model",
          margin: true
        },
        {
          label: "生产日期",
          prop: "produceDate"
        },
        {
          label: "生产厂家",
          prop: "produceManufacturers"
        },
        {
          label: "出厂编号",
          prop: "manufacturesNum"
        },
        {
          label: "设备重量(Kg)",
          prop: "weight"
        },
        {
          label: "使用年限(年)",
          prop: "useYear",
          margin: true
        },
        {
          label: "固定资产编号",
          prop: "fixedAssetsNum"
        },
        {
          label: "安装日期",
          prop: "installDate"
        },
        {
          label: "安装单位",
          prop: "installManufactures"
        },
        {
          label: "安装位置",
          prop: "location"
        },
        {
          label: "启用日期",
          prop: "useDate"
        },
        {
          label: "材质",
          prop: "texture"
        },
        {
          label: "责任人",
          prop: "dutyPersonName"
        },
        {
          label: "设备标签",
          prop: "tagName"
        }
      ]
    };
  },
  created() {
    let config = JSON.parse(globalConfig.VUE_EQUIPMENT_PROP);
    if (config.showCode) {
      this.infos.unshift({
        label: "设备编号",
        prop: "machineCode"
      });
    }
    if (config.showNo) {
      this.infos.unshift({
        label: "设备位号",
        prop: "machineNo"
      });
    }
    this.getData();
  },
  computed: {
    pictureUrl() {
      if (this.info?.fileDTO?.length) {
        return getFileUrlForFileSystem(this.info?.fileDTO[0].id, false);
      } else {
        return "";
      }
    }
  },
  methods: {
    ImagePreview,
    getData() {
      getEquipmentInfo({ id: this.id, changeFlage: "cd" }).then(res => {
        this.info = res;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .mark1 {
    .van-field__control {
      color: #24e351;
    }
  }
  .mark2 {
    .van-field__control {
      color: #1676ff;
    }
  }
  .mark3 {
    .van-field__control {
      color: #1676ff;
    }
  }
  .mark4 {
    .van-field__control {
      color: #f75858;
    }
  }
  .mark5 {
    .van-field__control {
      color: #ffa860;
    }
  }
}
.equipment-info-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  .equipment-info {
    flex: 1;
    height: 0;
    overflow-y: auto;
    .van-image {
      width: 100%;
      height: 150px;
      box-sizing: border-box;
      padding: 2.66667vw 4.26667vw;
    }
  }
}
</style>
